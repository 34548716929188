import { ref } from "vue";

export const initialize = () => {
  const error = ref("");
  const success = ref("");
  const setError = (content: string) => {
    error.value = content;
  };
  const setSuccess = (content: string) => {
    success.value = content;
  };

  return () => ({
    success,
    error,
    setSuccess,
    setError,
  });
};

export const useNotification = initialize();
