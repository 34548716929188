import { useToggle } from "@vueuse/core";
import { ref } from "vue";

export const initialize = () => {
  const [loading, toggleLoading] = useToggle();

  const text = ref("Processing");

  const setText = (content: string) => {
    text.value = content;
    toggleLoading(true);
  };

  return () => ({
    loading,
    toggleLoading,
    text,
    setText,
  });
};

export const useLoading = initialize();
