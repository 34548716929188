<script setup lang="ts">
import { useLoading } from "./composables/useLoading";
import Spinner from "./components/Common/Spinner.vue";
import Modal from "./components/Common/ModalComponent.vue";
import Notification from "./components/Common/NotificationComponent.vue";
import { onMounted, watchEffect } from "vue";
import { useNotification } from "./composables/useNotification";
const { loading, text } = useLoading();

const { error, success } = useNotification();

onMounted(() => {
  watchEffect(() => {
    if (error === undefined) return;
    if (error.value === "") return;

    setTimeout(() => {
      error.value = "";
    }, 3000);
  });

  watchEffect(() => {
    if (success === undefined) return;
    if (success.value === "") return;

    setTimeout(() => {
      success.value = "";
    }, 3000);
  });
});
</script>

<template>
  <div class="h-screen">
    <router-view></router-view>
  </div>
  <modal :visible="loading" class="z-100">
    <spinner :size="6" color="white" class="inline" />
    <h2 class="text-white inline">{{ text }}</h2>
  </modal>

  <Notification
    v-if="error !== ''"
    :msg="error"
    type="error"
    class="z-100"
  ></Notification>
  <Notification
    class="z-100"
    v-if="success !== ''"
    :msg="success"
    type="success"
  ></Notification>
</template>
